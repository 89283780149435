import { push } from "connected-react-router";
import { SET_CURRENT_USER } from "../../constants/reducerConstants";

export const setCurrentUser = user => ({type: SET_CURRENT_USER, payload: user});

export const logout = _ => {
    return(dispatch, getState) => {
        localStorage.clear();
        dispatch(setCurrentUser({}));
        dispatch(push('/'));
    };
};