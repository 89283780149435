import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import PublicationDocumentUpload from './PublicationDocumentUpload';

const PublicationEditor = ({ publication, onDocumentUpload, uploadedDocumentUrl, journalTitle }) => {
    return (
    <>
        <p>Journal: {journalTitle}</p>
        <PublicationDocumentUpload documentUrl={uploadedDocumentUrl ?? publication?.documentUrl} onDocumentUpload={onDocumentUpload}/>
        <Form.Row>
            <Form.Group as={Col} md={8}>
                <Form.Label>Title</Form.Label>
                <Form.Control
                    required
                    name="title"
                    type="text"
                    placeholder="What is like to be Ruby?"
                    maxLength="75"
                    defaultValue={publication?.title}
                />
            </Form.Group>
        </Form.Row>
        <Form.Row>
            <Form.Group as={Col}>
                <Form.Label>Abstract</Form.Label>
                <Form.Control
                    as="textarea"
                    minLength={10}
                    rows={10}
                    required
                    name="abstract"
                    type="text"
                    placeholder="Brief explanation (minimum 10 symbols)"
                    defaultValue={publication?.abstract}
                />
            </Form.Group>
        </Form.Row>
    </>
)
};

export default PublicationEditor;