import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import {PUBLICATIONS_URL} from '../../constants/index';
import { GetResource, PostResource, PutResource, DeleteResource } from '../../services/index'

export const getPublications = createAsyncThunk(
    'getPublications',
    async ({limitOnPage, offset, title}, {rejectWithValue}) => {    
        try {            
            const result = await GetResource(PUBLICATIONS_URL, {
                limit: limitOnPage,
                offset,
                title
            });

            console.log(result.headers["amount-all"])

            return {
                data: await result.data,
                totalPublications: result.headers["amount-all"]
            };
        }
        catch (err) {
            toast.error(err.response ? (err.response.data.error || err.response.data.errors.join(", ")) : err.message);
            return rejectWithValue(err.response ? err.response.data : err.message);
        }
    }
);

export const getPublication = createAsyncThunk(
    'getPublication',
    async (id, {rejectWithValue}) => {              
        try {            
            const result = await GetResource(PUBLICATIONS_URL+"/"+id);
            return await result.data;
        }
        catch (err) {
            toast.error(err.response ? (err.response.data.error || err.response.data.errors.join(", ")) : err.message);
            return rejectWithValue(err.response ? err.response.data : err.message);
        }
    }
);

export const createPublication = createAsyncThunk(
    'createPublication',
    async (publication, {rejectWithValue, dispatch}) => {              
        try {            
            const result = await PostResource(PUBLICATIONS_URL, publication);
            toast.success("Thaks for submitting your publication!");
            return result;
        }
        catch (err) {
            toast.error(err.response ? (err.response.data.error || err.response.data.errors.join(", ")) : err.message);
            return rejectWithValue(err.response ? err.response.data : err.message);
        }
    }
);

export const updatePublication = createAsyncThunk(
    'updatePublication',
    async (publication, {rejectWithValue, dispatch}) => {              
        try {            
            const result = await PutResource(PUBLICATIONS_URL+"/"+publication.id, publication);
            return result;
        }
        catch (err) {
            toast.error(err.response ? (err.response.data.error || err.response.data.errors.join(", ")) : err.message);
            return rejectWithValue(err.response ? err.response.data : err.message);
        }
    }
);

export const updatePublicationStatus = createAsyncThunk(
    'updatePublicationStatus',
    async ({id, status}, {rejectWithValue, dispatch}) => {              
        try {            
            const result = await PutResource(PUBLICATIONS_URL+"/"+id+"/status", {status});
            return result;
        }
        catch (err) {
            toast.error(err.response ? (err.response.data.error || err.response.data.errors.join(", ")) : err.message);
            return rejectWithValue(err.response ? err.response.data : err.message);
        }
    }
);

export const removePublication = createAsyncThunk(
    'removePublication',
    async (publication, {rejectWithValue, dispatch}) => {
        try {            
            const result = await DeleteResource(PUBLICATIONS_URL+"/"+publication.id);
            return result;
        }
        catch (err) {
            toast.error(err.response ? (err.response.data.error || err.response.data.errors.join(", ")) : err.message);
            return rejectWithValue(err.response ? err.response.data : err.message);
        }
    }
);

const publicationsSlice = createSlice({
    name: 'publications',
    initialState: {
        getPublications: {
            success: false,
            error: null,
            publications: [],
            totalPublications: 0
        },
        getPublication: {
            success: false,
            error: null,
            publication: null
        },
        updatePublication: {
            success: false,
            error: null
        },
        updatePublicationStatus: {
            success: false,
            error: null
        }
    },
    reducers: {
        resetPublication: (state, action) => {
            state.getPublication.success = false;
            state.getPublication.publication = null;
            state.getPublication.error = action.payload;
        }
    },
    extraReducers: {
        [getPublications.pending]: state => {
            state.getPublications.success = false;
            state.getPublications.error = null;
        },
        [getPublications.fulfilled]: (state, action) => {
            state.getPublications.success = true;
            state.getPublications.publications = action.payload.data;
            state.getPublications.totalPublications = action.payload.totalPublications
        },
        [getPublications.rejected]: (state, action) => {
            state.getPublications.success = false;
            state.getPublications.publications = [];
            state.getPublications.error = action.payload;
        },

        [getPublication.pending]: state => {
            state.getPublication.success = false;
            state.getPublication.error = null;
        },
        [getPublication.fulfilled]: (state, action) => {
            state.getPublication.success = true;
            state.getPublication.publication = action.payload;
        },
        [getPublication.rejected]: (state, action) => {
            state.getPublication.success = false;
            state.getPublication.publication = {};
            state.getPublication.error = action.payload;
        },

        [createPublication.pending]: state => {
            state.updatePublication.success = false;
            state.updatePublication.error = null;
        },
        [createPublication.fulfilled]: (state, action) => {
            state.updatePublication.success = true;
        },
        [createPublication.rejected]: (state, action) => {
            state.updatePublication.success = false;
            state.updatePublication.error = action.payload;
        },

        [updatePublication.pending]: state => {
            state.updatePublication.success = false;
            state.updatePublication.error = null;
        },
        [updatePublication.fulfilled]: (state, action) => {
            state.updatePublication.success = true;
        },
        [updatePublication.rejected]: (state, action) => {
            state.updatePublication.success = false;
            state.updatePublication.error = action.payload;
        },

        [updatePublicationStatus.pending]: state => {
            state.updatePublicationStatus.success = false;
            state.updatePublicationStatus.error = null;
        },
        [updatePublicationStatus.fulfilled]: (state, action) => {
            state.updatePublicationStatus.success = true;
        },
        [updatePublicationStatus.rejected]: (state, action) => {
            state.updatePublicationStatus.success = false;
            state.updatePublicationStatus.error = action.payload;
        },

        [removePublication.pending]: state => {
            state.updatePublication.success = false;
            state.updatePublication.error = null;
        },
        [removePublication.fulfilled]: (state, action) => {
            state.updatePublication.success = true;
        },
        [removePublication.rejected]: (state, action) => {
            state.updatePublication.success = false;
            state.updatePublication.error = action.payload;
        }
    }
});

export const {resetPublication} = publicationsSlice.actions;

export default publicationsSlice.reducer;