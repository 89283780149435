import '../Publications/Publication.scss';
import { Button, Container } from 'react-bootstrap';
import Publication from '../Publications/Publication';

const FullJournal = ({ journal, openPublication, approvePublication, declinePublication, publishJournal}) => {

  const acceptedPublications = journal?.publications.filter(p=>p.status === 2)
  const publicationsUnderReview = journal?.publications.filter(p=>p.status === 1)
  
  return (
    <Container>
      <h1 className="page-title">{journal?.title}</h1>
      <div className='two-sides'>
        <div>
          <span className='d-block'>Editor: <i>{journal?.editor?.firstName} {journal?.editor?.lastName}</i></span>
          <span>Accepting publications till <b>{new Date(journal?.publicationDeadline).toLocaleDateString()}</b></span>
        </div>
        {journal?.publishedAt 
          ? <span>Published on {new Date(journal.publishedAt).toLocaleDateString()}</span>
          : <Button onClick={publishJournal}>Publish the journal</Button>}
      </div>
      <div className='mt-5'>
        <b>Description</b>
        <p>{journal?.description}</p>
      </div>
      {!journal?.publishedAt && <div className='mt-5'>
        <b>Publications under review</b>
        {publicationsUnderReview?.map(p => (
          <Publication key={p.id} allowReview approvePublication={() => approvePublication(p.id)} declinePublication={() => declinePublication(p.id)} 
            publication={p} openPublication={e=>openPublication(e, p)}/>
        ))}
      </div>}
      <div className='mt-5'>
        <b>{journal?.publishedAt ? 'Publications' : 'Approved publications'}</b>
        {acceptedPublications?.map(p => (
          <Publication key={p.id} publication={p} openPublication={e=>openPublication(e, p)}/>
        ))}
      </div>
    </Container>
  );
};

export default FullJournal;