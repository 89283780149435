import { useEffect, useState } from "react";
import { Button, Container } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import Journal from "./Journal";
import { getJournals, removeJournal } from "./journalsSlice";
import JournalModalContainer from "./JournalModal/JournalModalContainer";
import ReactPaginate from "react-paginate";

const JournalsContainer = ({ selectMode, setSelectedJournal}) => {
    let history = useHistory();
    const journals = useSelector(state => state.journals.getJournals.journals);
    const totalJournalCount = useSelector(state => state.journals.getJournals.totalJournals);
    const dispatch = useDispatch();

    const [showModal, setShowModal] = useState(false);
    const [journalToEdit, setJournalToEdit] = useState(null);
    const [offset, setOffset] = useState(0);

    const limitOnPage = 3;

    useEffect(_ => {
        dispatch(getJournals({limitOnPage, offset}));
    }, [dispatch, offset])

    const openJournal = (event, journal) => {
        const el = event.target;
        if (el.nodeName !== "BUTTON"){
            if(selectMode)
              setSelectedJournal(journal)
            else
              history.push("/journals/"+journal.id);
        }
    }

    const onEditJournal = (j) => {
      setJournalToEdit(j);
      setShowModal(true);
    }

    const handlePageClick = ({selected}) => {
      setOffset(limitOnPage * selected);
    }

    const onCreateJournal = () => {
      setJournalToEdit(null);
      setShowModal(true);
    }

    return (
      <>
        <Container>
            {!selectMode && <h1 className="page-title">Unpublished Journals</h1>}
            <div>
              {journals.map(j => 
                <Journal key={j.id}
                  journal={j}
                  openJournal={e=>openJournal(e, j)} 
                  editJournal={_ => onEditJournal(j)}
                  removeJournal={_ => dispatch(removeJournal(j))}
                  selectMode={selectMode}
                />
              )}
              <ReactPaginate
                  className="pagination"
                  breakLabel="..."
                  nextLabel="next >"
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={limitOnPage}
                  pageCount={Math.ceil(totalJournalCount / limitOnPage)}
                  previousLabel="< previous"
                  renderOnZeroPageCount={null}
              />
              {!selectMode && <Button onClick={onCreateJournal}>Create new journal</Button>}
            </div>
        </Container>
        <JournalModalContainer show={showModal} setShow={setShowModal} journal={journalToEdit} 
          refreshJournals={() => dispatch(getJournals({limitOnPage, offset}))}/>
      </>
    )
}

export default JournalsContainer;