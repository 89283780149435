import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import { getJournal, publishJournal } from "./journalsSlice";
import FullJournal from "./FullJournal";
import { updatePublicationStatus } from "../Publications/publicationsSlice";
import { toast } from "react-toastify";

const FullJournalContainer = _ => {
    const { id } = useParams();
    const journal = useSelector(state => state.journals.getJournal.journal);
    const updatePublicationStatusSuccess = useSelector(state => state.publications.updatePublicationStatus.success);
    const publishJournalSyccess = useSelector(state => state.journals.publishJournal.success);
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(_ => {
        dispatch(getJournal(id));
    }, [dispatch, id, updatePublicationStatusSuccess, publishJournalSyccess])

    const openPublication = (event, pub) => {
      const el = event.target;
      if (el.nodeName !== "BUTTON")
          history.push("/publications/"+pub.id);
    }

    const approvePublication = (id) => {
      dispatch(updatePublicationStatus({id, status: 2}))
    }

    const declinePublication = (id) => {
      dispatch(updatePublicationStatus({id, status: 3}))
    }

    const onPublishJournal = async () => {
      if(journal.publications.some(p => p.status === 1))
        toast.error("Can't publish the journal because it has publications under review")
      else 
        await dispatch(publishJournal(id));
    }

    return (
      <FullJournal openPublication={openPublication} journal={journal} approvePublication={approvePublication} declinePublication={declinePublication}
        publishJournal={onPublishJournal}/>
    )
}

export default FullJournalContainer;