import { useEffect, useState } from "react";
import { Button, Container } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import Publication from "./Publication";
import { getPublication, getPublications, removePublication, resetPublication } from "./publicationsSlice";
import PublicationModalContainer from "./PublicationModal/PublicationModalContainer";
import FullPublication from "./FullPublication";
import Search from "./Search";
import usePrevious from "../../utils/customHooks/usePrevious";
import { toast } from "react-toastify";
import ReactPaginate from "react-paginate";

const PublicationsContainer = _ => {
    const { id } = useParams();
    let history = useHistory();
    const publications = useSelector(state => state.publications.getPublications.publications);
    const totalPublicationCount = useSelector(state => state.publications.getPublications.totalPublications);
    const dispatch = useDispatch();
    const publication = useSelector(state => state.publications.getPublication.publication);
    const isAuthenticated = useSelector(state => state.user.isAuthenticated)
    const [showModal, setShowModal] = useState(false);
    const [searchValue, setSearchValue] = useState("");
    const [offset, setOffset] = useState(0);
    const prevSearchValue = usePrevious(searchValue);

    const limitOnPage = 3;

    useEffect(_ => {
        dispatch(getPublications({limitOnPage, offset}));
    }, [dispatch, offset])
    
    useEffect(_ => {
        if(id)
            dispatch(getPublication(id))
        else{
            dispatch(resetPublication());
            setSearchValue("")
        }
    }, [id, dispatch])

    useEffect(_ => {
        if(!showModal){
            dispatch(resetPublication())
            setSearchValue("");
        }
    }, [showModal, dispatch])

    useEffect(_ => {
        if(searchValue.length >= 3)
            dispatch(getPublications({limitOnPage, offset, title: searchValue}))
        else if(searchValue.length < 3 && prevSearchValue?.length >= 3)
            dispatch(getPublications({limitOnPage, offset}));
    }, [searchValue, dispatch, prevSearchValue?.length, offset])

    const openPublication = (event, pub) => {
        const el = event.target;
        if (el.nodeName !== "BUTTON")
            history.push("/publications/"+pub.id);
    }

    const updatePublication = (pub) => {
        dispatch(getPublication(pub.id));
        setShowModal(true);
    }

    const handlePageClick = ({selected}) => {
        setOffset(limitOnPage * selected);
    }

    const refreshPublications = _ => {
        setSearchValue("");
        dispatch(getPublications({limitOnPage, offset}))
    }

    return (
        <>
            <Container>
                <h1 className="page-title">{id ? publication?.title : "Publications"}</h1>
                {id ? 
                    <FullPublication publication={publication}/> :
                    <>
                        <Search value={searchValue} handleChange={e => setSearchValue(e.target.value)}/>
                        {publications.map(p => 
                            <Publication key={p.id} publication={p}
                                openPublication={e=>openPublication(e, p)} 
                                updatePublication={_ => updatePublication(p)}
                                removePublication={_ => dispatch(removePublication(p))}
                            />
                        )}
                        <ReactPaginate
                            className="pagination"
                            breakLabel="..."
                            nextLabel="next >"
                            onPageChange={handlePageClick}
                            pageRangeDisplayed={limitOnPage}
                            pageCount={Math.ceil(totalPublicationCount / limitOnPage)}
                            previousLabel="< previous"
                            renderOnZeroPageCount={null}
                        />
                        <Button onClick={_=>isAuthenticated ? setShowModal(true) : toast.error("Not Authorized")}>Submit new publication</Button>
                    </>
                }
                
            </Container>
            <PublicationModalContainer show={showModal} setShow={setShowModal} publication={publication} refreshPublications={refreshPublications}/>
        </>
    )
}

export default PublicationsContainer;