import { configureStore } from '@reduxjs/toolkit';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { updateUser } from '../components/LoginRegister';
import publicationsSlice from '../components/Publications/publicationsSlice';
import loginRegisterSlice from '../components/LoginRegister/loginRegisterSlice';
import journalsSlice from '../components/Journals/journalsSlice';
import filesSlice from '../components/Files/filesSlice';

export const history = createBrowserHistory();

export const store = configureStore({
  reducer: {
    user: updateUser,
    loginRegister: loginRegisterSlice,
    publications: publicationsSlice,
    journals: journalsSlice,
    files: filesSlice,
    router: connectRouter(history)
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(routerMiddleware(history))
});
