import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import JournalModal from "./JournalModal";
import { createJournal, updateJournal } from "../journalsSlice";

const JournalModalContainer = ({show, setShow, journal, refreshJournals}) => {
    const [validated, setValidated] = useState(false);
    
    const dispatch = useDispatch();

    const handleSubmit = async event => {
        event.preventDefault();
        event.stopPropagation();
        const form = event.currentTarget;
        if (form.checkValidity() === true) {
            const newJournal = {
                ...journal,
                title: form.elements.title.value,
                description: form.elements.description.value,
                publicationDeadline: form.elements.publicationDeadline.value
            }

            console.log(journal)

            if(journal)
                await dispatch(updateJournal(newJournal));
            else
                await dispatch(createJournal(newJournal));

            refreshJournals();
            setShow(false);
        }
        setValidated(true);
    }

    useEffect(_ => {
        if(!show)
            return;

        setValidated(false);
    }, [show])

    return (
      <JournalModal show={show} setShow={setShow} validated={validated} handleSubmit={handleSubmit} journal={journal} />
    )
}

export default JournalModalContainer;