import React from 'react';
import Layout from '../Layout';
import { Redirect, Route, Switch } from 'react-router-dom';
import ErrorBoundry from '../ErrorBoundry';
import Error404 from '../Error404';
import { Suspense } from 'react';
import Spinner from '../Spinner';
import LoginContainer from '../LoginRegister';
import PublicationsContainer from '../Publications/PublicationsContainer'
import JournalsContainer from '../Journals/JournalsContainer';
import FullJournalContainer from '../Journals/FullJournalContainer';

const App = _ => (
  <Suspense fallback={<Spinner />}>
    <Layout>
      <ErrorBoundry>
        <Switch>
          <Redirect exact from="/" to="/publications" />
          <Route path='/publications/:id?' component={PublicationsContainer} />
          <Route exact path='/journals' component={JournalsContainer} />
          <Route path='/journals/:id' component={FullJournalContainer} />
          <Route path='/login' component={LoginContainer} />
          <Route component={Error404} />
        </Switch>
      </ErrorBoundry>
    </Layout>
  </Suspense>
);

export default App;