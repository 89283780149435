import '../Publications/Publication.scss';
import { Button } from 'react-bootstrap';

const Journal = ({ journal, openJournal, editJournal, removeJournal, selectMode }) => {

  const acceptedPublicationCount = journal.publications.filter(p=>p.status === 2).length
  const publicationsUnderReviewCount = journal.publications.filter(p=>p.status === 1).length

  return (
    <div className="publication two-sides" onClick={openJournal}>
        <div>
            <span className='d-block'>{journal.title}</span>
            <small className='d-block'>Editor: <i>{journal.editor.firstName} {journal.editor.lastName}</i></small>
            <small className='d-block'>{acceptedPublicationCount} publications, {publicationsUnderReviewCount} under review</small>
            <small className='d-block'>Requests open till {new Date(journal.publicationDeadline).toLocaleDateString()}</small>
        </div>
        {!selectMode && <div>
          <Button onClick={editJournal} className="publication-btn">✏️</Button>
          <Button onClick={removeJournal} className="publication-btn">🗑️</Button>
        </div>}
    </div >
  );
};

export default Journal;