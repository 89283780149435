import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { createPublication, updatePublication } from "../publicationsSlice";
import PublicationEditor from "./PublicationEditor";
import { uploadFile } from "../../Files/filesSlice";
import JournalsContainer from "../../Journals/JournalsContainer";
import { Button, Col, Form, Modal } from "react-bootstrap";

const PublicationModalContainer = ({show, setShow, publication, refreshPublications}) => {
    const [stage, setStage] = useState(1);
    const [validated, setValidated] = useState(false);
    const [uploadedDocumentUrl, setUploadedDocumentUrl] = useState(null);
    const [selectedJournal, setSelectedJournal] = useState(null);
    
    const dispatch = useDispatch();

    const onDocumentUpload = async file => {
        const formData = new FormData();
        formData.append("file", file);

        const result = await dispatch(uploadFile(formData));
        setUploadedDocumentUrl(result.payload);
    }

    const handleSubmit = async event => {
        event.preventDefault();
        event.stopPropagation();
        const form = event.currentTarget;
        if (form.checkValidity() === true) {
            const newPublication = {
                ...publication,
                title: form.elements.title.value,
                abstract: form.elements.abstract.value,
                documentUrl: uploadedDocumentUrl ?? publication?.documentUrl,
                journalId: selectedJournal?.id ?? publication?.journal?.id
            }
            if(publication)
                await dispatch(updatePublication(newPublication));
            else
                await dispatch(createPublication(newPublication));

            refreshPublications();

            setShow(false);
        }
        setValidated(true);
    }

    useEffect(_ => {
        if(!show)
            return;

        setSelectedJournal(null);
        setStage(1);
        setValidated(false);
        setUploadedDocumentUrl(null);
    }, [show])

    useEffect(_ => {
        if(!!selectedJournal)
            setStage(2);
    }, [selectedJournal])

    useEffect(_ => {
        if(publication?.id)
            setStage(2);
    }, [publication])

    return (
        
            <Modal centered show={show} onHide={_=>setShow(false)} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Add a publication</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form noValidate validated={validated} onSubmit={handleSubmit}>
                        {stage === 1 
                        ? <div>
                            <p>Please choose the journal</p>
                            <JournalsContainer selectMode setSelectedJournal={setSelectedJournal}/> 
                        </div>
                        : <PublicationEditor show={show} setShow={setShow} handleSubmit={handleSubmit} 
                            validated={validated} publication={publication}
                            journalTitle={selectedJournal?.title ?? publication?.journal?.title}
                            onDocumentUpload={onDocumentUpload} uploadedDocumentUrl={uploadedDocumentUrl}/>}
                        {stage === 2 && <Form.Row>
                            <Form.Group as={Col}>
                                <Button variant="primary" type="submit">Submit</Button>
                            </Form.Group>
                        </Form.Row>}
                    </Form>
                </Modal.Body>
            </Modal>
            
    )
}

export default PublicationModalContainer;