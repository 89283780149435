import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import {JOURNALS_URL} from '../../constants/index';
import { GetResource, PostResource, PutResource, DeleteResource } from '../../services/index'

export const getJournals = createAsyncThunk(
    'getJournals',
    async ({limitOnPage, offset}, {rejectWithValue}) => {              
        try {            
            const result = await GetResource(JOURNALS_URL, {
                limit: limitOnPage,
                offset
            });
            return {
              data: await result.data,
              totalJournals: result.headers["amount-all"]
            };
        }
        catch (err) {
            toast.error(err.response ? (err.response.data.error || err.response.data.errors.join(", ")) : err.message);
            return rejectWithValue(err.response ? err.response.data : err.message);
        }
    }
);

export const getJournal = createAsyncThunk(
    'getJournal',
    async (id, {rejectWithValue}) => {              
        try {            
            const result = await GetResource(JOURNALS_URL+"/"+id);
            return await result.data;
        }
        catch (err) {
            toast.error(err.response ? (err.response.data.error || err.response.data.errors.join(", ")) : err.message);
            return rejectWithValue(err.response ? err.response.data : err.message);
        }
    }
);

export const createJournal = createAsyncThunk(
    'createJournal',
    async (publication, {rejectWithValue, dispatch}) => {              
        try {            
            const result = await PostResource(JOURNALS_URL, publication);
            return result;
        }
        catch (err) {
            toast.error(err.response ? (err.response.data.error || err.response.data.errors.join(", ")) : err.message);
            return rejectWithValue(err.response ? err.response.data : err.message);
        }
    }
);

export const updateJournal = createAsyncThunk(
    'updateJournal',
    async (publication, {rejectWithValue, dispatch}) => {              
        try {            
            const result = await PutResource(JOURNALS_URL+"/"+publication.id, publication);
            return result;
        }
        catch (err) {
            toast.error(err.response ? (err.response.data.error || err.response.data.errors.join(", ")) : err.message);
            return rejectWithValue(err.response ? err.response.data : err.message);
        }
    }
);

export const publishJournal = createAsyncThunk(
  'publishJournal',
  async (id, {rejectWithValue, dispatch}) => {              
      try {            
          const result = await PutResource(JOURNALS_URL+"/"+id+"/publish");
          dispatch(getJournal(id));
          return result;
      }
      catch (err) {
          toast.error(err.response ? (err.response.data.error || err.response.data.errors.join(", ")) : err.message);
          return rejectWithValue(err.response ? err.response.data : err.message);
      }
  }
);

export const removeJournal = createAsyncThunk(
    'removeJournal',
    async (publication, {rejectWithValue, dispatch}) => {
        try {            
            const result = await DeleteResource(JOURNALS_URL+"/"+publication.id);
            return result;
        }
        catch (err) {
            toast.error(err.response ? (err.response.data.error || err.response.data.errors.join(", ")) : err.message);
            return rejectWithValue(err.response ? err.response.data : err.message);
        }
    }
);

const journalsSlice = createSlice({
    name: 'journals',
    initialState: {
        getJournals: {
          success: false,
          error: null,
          journals: []
        },
        getJournal: {
          success: false,
          error: null,
          journal: null
        },
        updateJournal: {
          success: false,
          error: null
        },
        publishJournal: {
          success: false,
          error: null
        }
    },
    extraReducers: {
        [getJournals.pending]: state => {
            state.getJournals.success = false;
            state.getJournals.error = null;
        },
        [getJournals.fulfilled]: (state, action) => {
            state.getJournals.success = true;
            state.getJournals.journals = action.payload.data;
            state.getJournals.totalJournals = action.payload.totalJournals
        },
        [getJournals.rejected]: (state, action) => {
            state.getJournals.success = false;
            state.getJournals.journals = [];
            state.getJournals.error = action.payload;
        },

        [getJournal.pending]: state => {
            state.getJournal.success = false;
            state.getJournal.error = null;
        },
        [getJournal.fulfilled]: (state, action) => {
            state.getJournal.success = true;
            state.getJournal.journal = action.payload;
        },
        [getJournal.rejected]: (state, action) => {
            state.getJournal.success = false;
            state.getJournal.journal = {};
            state.getJournal.error = action.payload;
        },

        [createJournal.pending]: state => {
            state.updateJournal.success = false;
            state.updateJournal.error = null;
        },
        [createJournal.fulfilled]: (state, action) => {
            state.updateJournal.success = true;
        },
        [createJournal.rejected]: (state, action) => {
            state.updateJournal.success = false;
            state.updateJournal.error = action.payload;
        },

        [updateJournal.pending]: state => {
            state.updateJournal.success = false;
            state.updateJournal.error = null;
        },
        [updateJournal.fulfilled]: (state, action) => {
            state.updateJournal.success = true;
        },
        [updateJournal.rejected]: (state, action) => {
            state.updateJournal.success = false;
            state.updateJournal.error = action.payload;
        },

        [publishJournal.pending]: state => {
          state.publishJournal.success = false;
          state.publishJournal.error = null;
        },
        [publishJournal.fulfilled]: (state, action) => {
            state.publishJournal.success = true;
        },
        [publishJournal.rejected]: (state, action) => {
            state.publishJournal.success = false;
            state.publishJournal.error = action.payload;
        },

        [removeJournal.pending]: state => {
            state.updateJournal.success = false;
            state.updateJournal.error = null;
        },
        [removeJournal.fulfilled]: (state, action) => {
            state.updateJournal.success = true;
        },
        [removeJournal.rejected]: (state, action) => {
            state.updateJournal.success = false;
            state.updateJournal.error = action.payload;
        }
    }
});

export default journalsSlice.reducer;