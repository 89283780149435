import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import './Login.scss';
import Login from './Login';
import { login, register, resetLoginError } from "../loginRegisterSlice";

const LoginContainer = _ => {
  // стейт з Redax
  const loginError = useSelector(state => state.loginRegister.login.error);
  const registerError = useSelector(state => state.loginRegister.register.error);

  // локальний стейт
  const [detectResult, setDetectResult] = useState(null);
  const [validated, setValidated] = useState(false);
  const [email, setEmail] = useState();
  const [password, setPassword] = useState("");
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [submitActive, setSubmitActive] = useState(true);
   // dispatch from redux
   const dispatch = useDispatch();
  
  const handleSubmit = async (event, func) => {
    event.preventDefault();
    event.stopPropagation();    
    const form = event.currentTarget;
    if (form.checkValidity() === true) {
      setSubmitActive(false);
      if (func === "login") {            
        const res = await dispatch(login({email, password: !detectResult ? "" : password}));

        if(detectResult === null){
          const isNotFoundError = res.payload === 'User with this email not found';
          setDetectResult(isNotFoundError ? "new": "existing");
          dispatch(resetLoginError());
        }
      }
      else{
        dispatch(register({email, password, firstName, lastName}));
      }
    }    
    setValidated(true);    
  };
  
  return (
    <Login   
      validated={validated}
      handleSubmit={handleSubmit}
      setEmail={(e) => setEmail(e.target.value)}
      email={email}
      submitActive={submitActive}
      firstName={firstName}
      lastName={lastName}
      onFirstNameChange={(e) => setFirstName(e.target.value)}
      onLastNameChange={(e) => setLastName(e.target.value)}
      onPasswordChange={(e) => setPassword(e.target.value)}
      loginError={loginError}
      detectResult={detectResult}
      registerError={registerError}
    />
  )
};

export default LoginContainer;