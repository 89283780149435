import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';

const PublicationDocumentUpload = ({ documentUrl, onDocumentUpload }) => {

    const onSelectFile = e => {
        if (e.target.files && e.target.files.length > 0) {
            onDocumentUpload(e.target.files[0]);
        }
    };

    return (
        <Form.Row>
            <Form.Group as={Col} md>
                <Form.File custom >
                    <Form.File.Input accept="application/pdf" onChange={onSelectFile} />
                    <Form.File.Label data-browse="Review">
                        {(!documentUrl ? "Upload" : "Change") + " document"}
                    </Form.File.Label>
                </Form.File>
            </Form.Group>
        </Form.Row>
    )
};

export default PublicationDocumentUpload;