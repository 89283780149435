import 'react-phone-number-input/style.css';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import { Link } from 'react-router-dom';

const Login = ({ validated, handleSubmit, firstName, lastName,
    onFirstNameChange, onLastNameChange, email, setEmail, loginError, onPasswordChange, detectResult,
    registerError}) => {
    const { Row, Group, Control } = Form;
    return (
        <Modal centered show={true} size="md">
            <Modal.Body className="pt-5">
                <Form
                    noValidate
                    validated={validated}
                    onSubmit={(event) => detectResult === "new" ? handleSubmit(event, "register") : handleSubmit(event, "login")}
                    className="text-center">
                    <Row>
                        <Group as={Col} md="12">
                            Please enter your email address
                        </Group>
                    </Row>
                    <Row>
                        <Group as={Col} md="12" className="mt-4">
                            <Control
                                required
                                defaultValue={email}
                                name="email"
                                type="text"
                                autoComplete="email"
                                placeholder="Email address"
                                onChange={setEmail}
                            />
                        </Group>
                    </Row>
                    <>
                        <Row style={{ visibility: detectResult === "new" ? 'visible' : 'hidden' }}>
                            <Group as={Col} md="12">
                                <Control
                                    required={detectResult === "new"}
                                    defaultValue={firstName}
                                    name="firstName"
                                    type="text"
                                    autoComplete="given-name"
                                    placeholder="Enter your first name"
                                    onChange={onFirstNameChange}
                                />
                            </Group>
                        </Row>
                        <Row style={{ visibility: detectResult === "new" ? 'visible' : 'hidden' }}>
                            <Group as={Col} md="12">
                                <Control
                                    required={detectResult === "new"}
                                    defaultValue={lastName}
                                    name="lastName"
                                    type="text"
                                    autoComplete="last-name"
                                    placeholder="Enter your last name"
                                    onChange={onLastNameChange}
                                />
                            </Group>
                        </Row>
                    </>

                    <Row style={{ visibility: detectResult ? 'visible' : 'hidden' }}>
                        <Group as={Col} md="12">
                            <Control
                                required={detectResult}
                                type="password"
                                name="password"
                                placeholder="Enter your password"
                                onChange={onPasswordChange}
                            />
                        </Group>
                    </Row>
                    {detectResult && loginError && <span style={{ color: 'red' }}>{loginError}</span>}
                    {detectResult && registerError && <span style={{ color: 'red' }}>{registerError}</span>}
                    <Row>
                        <Group as={Col} md="12">
                            <span className="proposal-accept">When you register, you agree with the
                                <Link target="_blank" to="/offer"> public offer</Link></span>
                        </Group>
                    </Row>
                    <Row>
                        <Group as={Col} md="12" className="mb-0">
                            <Button
                                disabled={false}
                                variant="primary"
                                type="submit">
                                {!detectResult ? 'Next' : (detectResult === "new" ? 'Register' : 'Login')}
                            </Button>
                        </Group>
                    </Row>
                </Form>
            </Modal.Body>
        </Modal>
    )
};

export default Login;