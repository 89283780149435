import { Link } from 'react-router-dom';
import './Publication.scss';
import { Button } from 'react-bootstrap';

const Publication = ({ openPublication, allowReview, approvePublication, declinePublication, publication: {title, author, abstract, journal} }) => {

    const onJournalClick = (e) => {
        e.stopPropagation();
    }

    return (
        <div className="publication two-sides" onClick={openPublication}>
            <div>
                <div>{title}</div>
                <small className='d-block'>
                    <i>by {author?.firstName} {author?.lastName}</i>
                    {journal && <span> | <Link onClick={onJournalClick} className='journal-link' to={`/journals/${journal.id}`}>{journal.title}</Link></span>}
                </small>
                <small className='abstract'>{abstract}</small>
            </div>
            {allowReview && <span>
                <Button onClick={approvePublication} className="publication-btn">✅</Button>
                <Button onClick={declinePublication} className="publication-btn">❌</Button>
            </span>}
        </div >
    );
};

export default Publication;