import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {LOGIN_URL, REGISTER_URL} from '../../constants/index';
import { PostResource } from '../../services/index'
import { setCurrentUser } from './actions';
import { SetTokensGetUser } from '../../services/index';
import { push } from 'connected-react-router';

export const login = createAsyncThunk(
    'login',
    async (loginData, {rejectWithValue, dispatch}) => {              
        try {            
            const result = await PostResource(LOGIN_URL, loginData);

            if(result.token){
              const user = SetTokensGetUser(result.token);
              dispatch(setCurrentUser(user))
              dispatch(push("/publications"))
            }

            return result;
        }
        catch (err) {
            console.log(err.response)
            return rejectWithValue(err.response?.data ? err.response.data.message : err.message);
        }
    }
);

export const register = createAsyncThunk(
  'register',
  async (registerData, {rejectWithValue, dispatch}) => {              
      try {            
          const result = await PostResource(REGISTER_URL, registerData);

          if(result.token){
            const user = SetTokensGetUser(result.token);
            dispatch(setCurrentUser(user));
            dispatch(push("/publications"))
          }

          return result;
      }
      catch (err) {
          return rejectWithValue(err.response ? err.response.data : err.message);
      }
  }
);

const loginRegisterSlice = createSlice({
    name: 'loginRegister',
    initialState: {
        login: {
            success: false,
            error: null,
        },
        register: {
            success: false,
            error: null
        },
    },
    reducers: {
        resetLoginError: (state, action) => {
            state.login.error = null;
        }
    },
    extraReducers: {
        [login.pending]: state => {
            state.login.success = false;
            state.login.error = null;
        },
        [login.fulfilled]: (state, action) => {
            state.login.success = true;
        },
        [login.rejected]: (state, action) => {
            state.login.success = false;
            state.login.error = action.payload;
        },

        [register.pending]: state => {
            state.register.success = false;
            state.register.error = null;
        },
        [register.fulfilled]: (state, action) => {
            state.register.success = true;
        },
        [register.rejected]: (state, action) => {
            state.register.success = false;
            state.register.error = action.payload;
        },
    }
});

export const {resetLoginError} = loginRegisterSlice.actions;

export default loginRegisterSlice.reducer;