import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import { Button } from 'react-bootstrap';

const JournalModal = ({ show, validated, setShow, handleSubmit, journal }) => {
    return (
      <Modal centered show={show} onHide={_=>setShow(false)} size="lg">
        <Modal.Header closeButton>
            <Modal.Title>Add a journal</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Form.Row>
                <Form.Group as={Col} md={8}>
                    <Form.Label>Title</Form.Label>
                    <Form.Control
                        required
                        name="title"
                        type="text"
                        placeholder="What is like to be Ruby?"
                        maxLength="75"
                        defaultValue={journal?.title}
                    />
                </Form.Group>
            </Form.Row>
            <Form.Row>
                <Form.Group as={Col}>
                    <Form.Label>Description</Form.Label>
                    <Form.Control
                        as="textarea"
                        minLength={10}
                        rows={10}
                        required
                        name="description"
                        type="text"
                        placeholder="Brief explanation (minimum 10 symbols)"
                        defaultValue={journal?.description}
                    />
                </Form.Group>
            </Form.Row>
            <Form.Row>
                <Form.Group as={Col}>
                    <Form.Label>Deadline for adding publications</Form.Label>
                    <Form.Control
                      required
                      name="publicationDeadline"
                      type="date"
                      defaultValue={journal?.publicationDeadline.split('T')[0]}
                    />
                </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col}>
                  <Button variant="primary" type="submit">Submit</Button>
              </Form.Group>
            </Form.Row>
          </Form>
        </Modal.Body>
    </Modal>
)
};

export default JournalModal;