import Spinner from '../Spinner';
import './Publication.scss';
import { Document, Page, pdfjs } from 'react-pdf';
import { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const FullPublication = ({publication}) => {
    const [numPages, setNumPages] = useState();
    const [pageNumber, setPageNumber] = useState(1);

    useEffect(() => { pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`});

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
    }

    const changePage = (offset) => {
        setPageNumber(prevPageNumber => prevPageNumber + offset);
    }

    const previousPage = () => {
        changePage(-1);
    }

    const nextPage = () => {
        changePage(1);
    }

    const downloadDocument = () => {
        const link = document.createElement('a');
        link.href = publication.documentUrl;
        link.target = '_blank';
        link.setAttribute('download', 'file.pdf');

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
    }

    return (
        publication ?
        <>
            <div>
                <span>by {publication?.author?.firstName} {publication?.author?.lastName}</span>
                <p>Part of <Link className='journal-link' to={'/journals/'+publication?.journal?.id}>{publication?.journal?.title}</Link></p>
            </div>
            <div className='mt-5'>
                <b>Abstract</b>
                <p>{publication?.abstract}</p>
            </div>
            <div className='mt-5'>
                <h4>Contents</h4>
                <Document 
                    className='pdf-document' 
                    file={publication?.documentUrl}
                    onLoadSuccess={onDocumentLoadSuccess}>
                        <Page height={500} className='pdf-page' pageNumber={pageNumber} />
                </Document>
                <div className='mt-3'>
                    <p>Page {pageNumber || (numPages ? 1 : '--')} of {numPages || '--'}</p>
                    <Button
                        variant="primary"
                        disabled={pageNumber <= 1}
                        onClick={previousPage}
                    >
                        Previous
                    </Button>
                    <Button
                        className='ml-3'
                        variant="primary"
                        disabled={pageNumber >= numPages}
                        onClick={nextPage}
                    >
                        Next
                    </Button>
                    <Button
                        className='ml-5'
                        variant="outline"
                        onClick={downloadDocument}
                    >
                        💾 Download
                    </Button>
                </div>
            </div>
        </> : 
        <Spinner/>
    );
};

export default FullPublication;