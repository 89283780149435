//let BASE_URL = `https://api.rails.viwi.app/`;
let BASE_URL = `http://localhost:5000/`;

if(process.env.NODE_ENV === 'production')
    BASE_URL = `https://api.${window.location.host}/`;

export {BASE_URL};

export const IMAGES_URL = 'https://rails-blog.fra1.digitaloceanspaces.com/';

export const LOGIN_URL= 'api/v1/auth/login';
export const REGISTER_URL= 'api/v1/auth/register';

export const PUBLICATIONS_URL = 'api/v1/publications';

export const JOURNALS_URL = 'api/v1/journals';

export const FILES_URL = 'api/v1/files';