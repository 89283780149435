import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { PostFile } from '../../services/index'
import { FILES_URL } from '../../constants';

export const uploadFile = createAsyncThunk(
    'uploadFile',
    async (formData, {rejectWithValue, dispatch}) => {              
        try {            
            const result = await PostFile(FILES_URL, formData);
            return result;
        }
        catch (err) {
            toast.error(err.response ? (err.response.data.error || err.response.data.errors.join(", ")) : err.message);
            return rejectWithValue(err.response ? err.response.data : err.message);
        }
    }
);

const filesSlice = createSlice({
    name: 'files',
    initialState: {
        uploadFile: {
            success: false,
            error: null,
            fileUrl: null
        },
    },
    extraReducers: {
        [uploadFile.pending]: state => {
            state.uploadFile.success = false;
            state.uploadFile.error = null;
        },
        [uploadFile.fulfilled]: (state, action) => {
            state.uploadFile.success = true;
            state.uploadFile.file = action.payload;
        },
        [uploadFile.rejected]: (state, action) => {
            state.uploadFile.success = false;
            state.uploadFile.file = null;
            state.uploadFile.error = action.payload;
        },
    }
});

export default filesSlice.reducer;